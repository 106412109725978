import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {MessageService} from 'primeng/api';
import {HttpErrorCodes} from '@app/interceptors/httpErrorCodes';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private messageService: MessageService) {}
  private readonly LIFE_OF_OPEN_DIALOG = 5000;

  handleError(error: Error): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error);
    }
    return throwError(error);
  }

  private handleHttpError(error: HttpErrorResponse): void {
    switch (error.status) {
      case HttpErrorCodes.BadRequest:
        this.messageService.add({
          severity: 'error',
          summary: '400 Bad request',
          detail: error.message + '\n' + error.error?.message,
          life: this.LIFE_OF_OPEN_DIALOG,
        });
        break;

      case HttpErrorCodes.Unauthorized:
        this.messageService.add({
          severity: 'error',
          summary: '401 Unauthorized',
          detail: error.message + '\n' + error.error?.message,
          life: this.LIFE_OF_OPEN_DIALOG,
        });
        break;

      case HttpErrorCodes.Forbidden:
        this.messageService.add({
          severity: 'error',
          summary: 'User does not have permission to access below URL',
          detail: error.url,
          life: this.LIFE_OF_OPEN_DIALOG,
        });
        break;

      case HttpErrorCodes.NotFound:
        this.messageService.add({
          severity: 'error',
          summary: $localize`:@@common.object-does-not-exist:Object does not exist`,
          life: this.LIFE_OF_OPEN_DIALOG,
        });
        break;

      case HttpErrorCodes.ServerError:
        this.messageService.add({
          severity: 'error',
          summary: '500 Internal Server error',
          detail: error.message + '\n' + error.error?.message,
          life: this.LIFE_OF_OPEN_DIALOG,
        });
        break;

      default:
        this.messageService.add({
          severity: 'error',
          summary: error.status + error.statusText,
          detail: error.message + '\n' + error.error?.message,
          life: this.LIFE_OF_OPEN_DIALOG,
        });
        break;
    }
  }
}
