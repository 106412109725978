<button *ngIf="!isMenuFixed" id="menu-button" pButton pRipple (click)="isVisible = true" class="p-button-rounded">
  <fa-icon [icon]="['fas', 'bars']"></fa-icon>
</button>
<p-sidebar
  #sidebar
  [(visible)]="isMenuFixed || isVisible"
  position="right"
  [dismissible]="!isMenuFixed"
  [showCloseIcon]="!isMenuFixed"
  [style]="{width: '250px'}"
  [modal]="false"
>
  <ng-template pTemplate="header">
    <app-project-logo></app-project-logo>
  </ng-template>
  <ng-template pTemplate="content">
    <ul class="menu-list">
      <li>
        <a routerLink="/map">
          <fa-icon class="list-item-icon" [icon]="['fas', 'map']"></fa-icon>
          <span i18n="@@main-menu.map-view">Map View</span>
        </a>
      </li>
      <li *ngIf="checkHasAllRoles(readDataRoles)">
        <a routerLink="/analytics">
          <fa-icon class="list-item-icon" [icon]="['fas', 'chart-line']"></fa-icon>
          <span i18n="@@main-menu.analytics">Analytics</span>
        </a>
      </li>
      <li *ngIf="checkHasAllRoles(readDataRoles)">
        <a routerLink="/details">
          <fa-icon class="list-item-icon" [icon]="['fas', 'clipboard-list']"></fa-icon>
          <span i18n="@@main-menu.detailed-view">Details</span>
        </a>
      </li>
      <li *ngIf="checkHasAllRoles(readDataRoles)">
        <a routerLink="/registration" (click)="isVisible = false">
          <fa-icon class="list-item-icon" [icon]="['fas', 'check-square']"></fa-icon>
          <span i18n="@@main-menu.registration">Registration</span>
        </a>
      </li>
      <li *ngIf="checkHasAnyRole(controlCenterRoles)">
        <a routerLink="/control">
          <fa-icon class="list-item-icon" [icon]="['fas', 'gamepad']"></fa-icon>
          <span i18n="@@main-menu.control-center">Control Center</span>
        </a>
      </li>

      <li>
        <app-lang-switcher></app-lang-switcher>
      </li>

      <li (click)="onLogout()">
        <a>
          <fa-icon class="list-item-icon" [icon]="['fas', 'door-open']"></fa-icon>
          <span i18n="@@main-menu.logout">Logout</span>
        </a>
      </li>
    </ul>
  </ng-template>
</p-sidebar>
