export const translationMap = {
  dayNames: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  monthNames: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  today: 'Heute',
  clear: 'Zurücksetzen',
  weekHeader: 'Wk',
  upload: 'Hochladen',
  cancel: 'Abbrechen',
  choose: 'Auswählen',
  matchAll: 'Alle stimmen überein',
  matchAny: 'Einer stimmt überein ',
  dateIs: 'Datum ist',
  dateIsNot: 'Datum ist nicht',
  dateBefore: 'Datum ist davor',
  dateAfter: 'Datum ist danach',
  addRule: 'Regel hinzufügen',
  removeRule: 'Regel entfernen',
  startsWith: 'Beginnt mit',
  contains: 'Enthält',
  notContains: 'Enthält nicht',
  endsWith: 'Endet mit',
  equals: 'Gleich',
  notEquals: 'Nicht gleich',
  noFilter: 'Kein Filter',
  apply: 'Anwenden',
};
