import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SidebarActions} from '@app/store/actions/sidebar.actions';
import {ActivePanelEnum} from '../../../widgets/sidebar/activePanel.enum';
import ToggleVisibility = SidebarActions.ToggleVisibility;
import SwitchSidebarActivePanel = SidebarActions.SwitchSidebarActivePanel;
import SetSidebarFormView = SidebarActions.SetSidebarFormView;
import SetSidebarVisibility = SidebarActions.SetSidebarVisibility;

export class SidebarStateModel {
  isVisible!: boolean;
  activePanel!: string;
  isFormView!: boolean;
}

@State<SidebarStateModel>({
  name: 'sidebar',
  defaults: {
    isVisible: false,
    activePanel: ActivePanelEnum.LAYER_LIST,
    isFormView: false,
  },
})
@Injectable()
export class SidebarState {
  @Selector([SidebarState])
  static getIsFormView(state: SidebarStateModel) {
    return state.isFormView;
  }

  @Selector([SidebarState])
  static getIsVisible(state: SidebarStateModel) {
    return state.isVisible;
  }

  @Selector([SidebarState])
  static getActivePanel(state: SidebarStateModel) {
    return state.activePanel;
  }

  @Action(ToggleVisibility)
  ToggleVisibility({getState, patchState}: StateContext<SidebarStateModel>) {
    patchState({
      isVisible: !getState().isVisible,
    });
  }

  @Action(SetSidebarVisibility)
  SetSidebarVisibility({patchState}: StateContext<SidebarStateModel>, {isVisible}: SetSidebarVisibility) {
    patchState({
      isVisible,
    });
  }

  @Action(SwitchSidebarActivePanel)
  SwitchSidebarActivePanel({patchState}: StateContext<SidebarStateModel>, {activePanel}: SwitchSidebarActivePanel) {
    const isObjectInfoPanel = activePanel === ActivePanelEnum.OBJECT_INFO;
    patchState({
      activePanel,
    });
    if (isObjectInfoPanel) {
      patchState({isVisible: true});
    }
  }

  @Action(SetSidebarFormView)
  SetSidebarFormView({patchState}: StateContext<SidebarStateModel>, {isFormView}: SetSidebarFormView) {
    patchState({
      isFormView,
    });
  }
}
