import {ILayerState} from '@shared/models/layer-state.interface';

export interface ILayerStates {
  [key: string]: ILayerState;
}

export namespace LayerListActions {
  export class AddLayers {
    static readonly type = '[LAYER_LIST] AddLayers';

    constructor(public layersData: ILayerStates) {}
  }

  export class UpdateLayerState {
    static readonly type = '[LAYER_LIST] UpdateLayerState';

    constructor(public layerName: string, public property: Partial<ILayerState>) {}
  }

  export class SetLayerOrder {
    static readonly type = '[LAYERS_LIST] SetOrder';

    constructor(public layersOrder: string[][]) {}
  }

  export class AddActiveGroupIndex {
    static readonly type = '[LAYERS_LIST] AddActiveGroupIndex';

    constructor(public index: number) {}
  }

  export class ClearActiveGroupIndex {
    static readonly type = '[LAYERS_LIST] ClearActiveGroupIndex';
  }
}
