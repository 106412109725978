import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {LayersConfigsActions} from '../actions/layers-configs.actions';
import {LayerGroupConfig} from '../../../shared/models/layer-group.interface';
import AddLayersConfigs = LayersConfigsActions.AddLayersConfigs;

export class LayersConfigStateModel {
  layerGroups!: LayerGroupConfig[];
}

@State<LayersConfigStateModel>({
  name: 'layersConfigs',
  defaults: {
    layerGroups: [],
  },
})
@Injectable()
export class LayersConfigsState {
  @Action(AddLayersConfigs)
  addLayers({patchState}: StateContext<LayersConfigStateModel>, {layerGroups}: AddLayersConfigs) {
    patchState({
      layerGroups,
    });
  }
}
