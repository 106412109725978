import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {IBasemap, IViewConfig} from 'viz-map';
import {AppConfigActions} from '../actions/app-config.actions';
import {ApiUrls} from '../../../shared/models/api-urls.interface';
import {LayerGroupConfig} from '../../../shared/models/layer-group.interface';
import SetConfig = AppConfigActions.SetConfig;

export class AppConfigStateModel {
  apiUrls!: ApiUrls | null;
  defaultView!: IViewConfig;
  mapTiles!: IBasemap[];
  databaseProjection!: string;
  layerGroups!: LayerGroupConfig[];
}

@State<AppConfigStateModel>({
  name: 'appConfig',
  defaults: {
    apiUrls: null,
    defaultView: {centerCoords: null, resolution: null, rotation: null} as unknown as IViewConfig,
    mapTiles: [],
    databaseProjection: 'EPSG:3857',
    layerGroups: [],
  },
})
@Injectable()
export class AppConfigState {
  @Action(SetConfig)
  SetConfig({setState}: StateContext<AppConfigStateModel>, {config}: SetConfig) {
    setState(config);
  }
}
