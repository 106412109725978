import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainMenuComponent} from './main-menu.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SidebarModule} from 'primeng/sidebar';
import {DividerModule} from 'primeng/divider';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {RouterModule} from '@angular/router';
import {LanguageSwitcherModule} from '../../features/lang-switcher/language-switcher.module';
import {ProjectLogoComponent} from './project-logo/project-logo.component';

@NgModule({
  declarations: [MainMenuComponent, ProjectLogoComponent],
  exports: [MainMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    SidebarModule,
    DividerModule,
    ButtonModule,
    RippleModule,
    LanguageSwitcherModule,
  ],
})
export class MainMenuModule {}
