import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LangSwitcherComponent} from './lang-switcher.component';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DropdownModule} from 'primeng/dropdown';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  declarations: [LangSwitcherComponent],
  exports: [LangSwitcherComponent],
  imports: [CommonModule, BrowserAnimationsModule, FormsModule, FontAwesomeModule, DropdownModule],
})
export class LanguageSwitcherModule {}
