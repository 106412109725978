import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrintPageComponent} from './print-page.component';
import {QRCodeModule} from 'angularx-qrcode';

@NgModule({
  declarations: [PrintPageComponent],
  imports: [CommonModule, QRCodeModule],
  exports: [PrintPageComponent],
})
export class PrintPageModule {}
