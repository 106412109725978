import {ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {translationMap} from '@app/core-translation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'dashboard';

  constructor(@Inject(LOCALE_ID) public locale: string, private config: PrimeNGConfig) {}

  ngOnInit() {
    if (this.locale === 'de') {
      this.config.setTranslation(translationMap);
    }
  }
}
