export const ROLE_CREATE_VOLUNTEER = 'ROLE_CREATE_VOLUNTEER';
export const ROLE_DELETE_VOLUNTEER = 'ROLE_DELETE_VOLUNTEER';
export const ROLE_READ_VOLUNTEER = 'ROLE_READ_VOLUNTEER';
export const ROLE_READ_MATCH = 'ROLE_READ_MATCH';
export const ROLE_READ_REPORT = 'ROLE_READ_REPORT';
export const ROLE_READ_WORKLOAD = 'ROLE_READ_WORKLOAD';
export const ROLE_READ_CALL = 'ROLE_READ_CALL';
export const ROLE_CREATE_CALL = 'ROLE_CREATE_CALL';
export const ROLE_DELETE_MISSION_STREAM = 'ROLE_DELETE_MISSION_STREAM';
export const ROLE_DELETE_PROFILE_SERVICE_CACHE = 'ROLE_DELETE_PROFILE_SERVICE_CACHE';
export const ROLE_DELETE_MATCHER_CACHE = 'ROLE_DELETE_MATCHER_CACHE';
export const ROLE_DELETE_CONTENT_SERVICE_CACHE = 'ROLE_DELETE_CONTENT_SERVICE_CACHE';
export const ROLE_DELETE_VOLUNTEER_STREAM = 'ROLE_DELETE_VOLUNTEER_STREAM';
export const ROLE_DELETE_MESSAGE_STREAM = 'ROLE_DELETE_MESSAGE_STREAM';
export const ROLE_READ_INFO = 'ROLE_READ_INFO';
export const ROLE_DELETE_NOTIFICATION_GENERATOR_SERVICE_CACHE = 'ROLE_DELETE_NOTIFICATION_GENERATOR_SERVICE_CACHE';
export const ROLE_DELETE_MESSAGE_SERVICE_CACHE = 'ROLE_DELETE_MESSAGE_SERVICE_CACHE';
export const ROLE_READ_RUN_STATUS = 'ROLE_READ_RUN_STATUS';
export const ROLE_UPDATE_TRIGGER_MATCHING = 'ROLE_UPDATE_TRIGGER_MATCHING';
export const ROLE_DELETE_REGISTRATION = 'ROLE_DELETE_REGISTRATION';
export const ROLE_SEARCH_REGISTRATIONS = 'ROLE_SEARCH_REGISTRATIONS';

export const CONTROL_CENTER_ROLES = [
  ROLE_DELETE_CONTENT_SERVICE_CACHE,
  ROLE_DELETE_PROFILE_SERVICE_CACHE,
  ROLE_DELETE_MESSAGE_SERVICE_CACHE,
  ROLE_DELETE_MISSION_STREAM,
  ROLE_DELETE_NOTIFICATION_GENERATOR_SERVICE_CACHE,
  ROLE_DELETE_VOLUNTEER_STREAM,
  ROLE_DELETE_MESSAGE_STREAM,
  ROLE_DELETE_MATCHER_CACHE,
  ROLE_UPDATE_TRIGGER_MATCHING,
];

export const READ_DATA_ROLES = [
  ROLE_READ_VOLUNTEER,
  ROLE_READ_MATCH,
  ROLE_READ_REPORT,
  ROLE_READ_WORKLOAD,
  ROLE_READ_CALL,
  ROLE_SEARCH_REGISTRATIONS,
];

export const ADMIN_ROLES = [
  ROLE_DELETE_CONTENT_SERVICE_CACHE,
  ROLE_DELETE_PROFILE_SERVICE_CACHE,
  ROLE_DELETE_MESSAGE_SERVICE_CACHE,
  ROLE_DELETE_MISSION_STREAM,
  ROLE_DELETE_NOTIFICATION_GENERATOR_SERVICE_CACHE,
  ROLE_DELETE_VOLUNTEER_STREAM,
  ROLE_DELETE_MESSAGE_STREAM,
  ROLE_DELETE_REGISTRATION,
];

export const MANAGER_ROLES = [
  ROLE_CREATE_VOLUNTEER,
  ROLE_DELETE_VOLUNTEER,
  ROLE_CREATE_CALL,
  ROLE_DELETE_MATCHER_CACHE,
  ROLE_UPDATE_TRIGGER_MATCHING,
];

export const VIEWER_ROLES = [
  ROLE_READ_CALL,
  ROLE_READ_REPORT,
  ROLE_READ_WORKLOAD,
  ROLE_READ_VOLUNTEER,
  ROLE_READ_MATCH,
  ROLE_READ_INFO,
  ROLE_READ_RUN_STATUS,
];
