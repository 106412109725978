<div class="flex flex-row align-items-center">
  <svg class="logo" width="164" height="142" viewBox="0 0 164 142" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="82.0002" cy="59.9999" r="74.7912" fill="url(#paint0_radial_1675_8454)" fill-opacity="0.04" />
    <circle cx="82" cy="59.9999" r="52.2637" fill="url(#paint1_radial_1675_8454)" fill-opacity="0.1" />
    <circle cx="82" cy="60" r="28.8352" fill="url(#paint2_radial_1675_8454)" fill-opacity="0.3" />
    <circle cx="82" cy="60" r="7.88461" fill="#FF8A00" />
    <defs>
      <radialGradient
        id="paint0_radial_1675_8454"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(82.0002 59.9999) rotate(90) scale(74.7912)"
      >
        <stop offset="0.5625" stop-color="#FF8A00" stop-opacity="0" />
        <stop offset="1" stop-color="#FF8A00" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1675_8454"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(82 59.9999) rotate(90) scale(52.2637)"
      >
        <stop offset="0.5625" stop-color="#FF8A00" stop-opacity="0" />
        <stop offset="1" stop-color="#FF8A00" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1675_8454"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(82 60) rotate(90) scale(28.8352)"
      >
        <stop offset="0.5625" stop-color="#FF8A00" stop-opacity="0" />
        <stop offset="1" stop-color="#FF8A00" />
      </radialGradient>
    </defs>
  </svg>
  <h2>KatHelferPRO</h2>
</div>
