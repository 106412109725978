export namespace SidebarActions {
  export class ToggleVisibility {
    static readonly type = '[SIDEBAR] ToggleVisibility';
  }

  export class SetSidebarVisibility {
    static readonly type = '[SIDEBAR] SetVisibility';

    constructor(public isVisible: boolean) {}
  }

  export class SwitchSidebarActivePanel {
    static readonly type = '[SIDEBAR] SWITCH_ACTIVE_PANEL';

    constructor(public activePanel: string) {}
  }

  export class SetSidebarFormView {
    static readonly type = '[SIDEBAR] SET_Form_View';

    constructor(public isFormView: boolean) {}
  }
}
