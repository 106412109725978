import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintPageComponent implements OnInit {
  link$: Observable<string>;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.link$ = this.store.select((state) => state.shared.externalLink);
  }
}
