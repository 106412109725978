import {Action, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ILayerStates, LayerListActions} from '@app/store/actions/layer-list.actions';
import AddLayers = LayerListActions.AddLayers;
import UpdateLayerState = LayerListActions.UpdateLayerState;
import SetLayerOrder = LayerListActions.SetLayerOrder;
import AddActiveGroupIndex = LayerListActions.AddActiveGroupIndex;
import ClearActiveGroupIndex = LayerListActions.ClearActiveGroupIndex;

export interface ILayersOrder {
  [key: string]: string[];
  order: string[];
}

export class LayerListModel {
  layers!: ILayerStates;
  layersOrder!: string[][];
  activeGroupIndex!: number[];
}

@State<LayerListModel>({
  name: 'layerList',
  defaults: {
    layers: {},
    layersOrder: [],
    activeGroupIndex: [],
  },
})
@Injectable()
export class LayerListState {
  @Action(AddLayers)
  addLayers({patchState}: StateContext<LayerListModel>, {layersData}: AddLayers) {
    patchState({
      layers: layersData,
    });
  }

  @Action(UpdateLayerState)
  UpdateLayerState({getState, patchState}: StateContext<LayerListModel>, {layerName, property}: UpdateLayerState) {
    const layers = getState().layers;

    patchState({
      layers: {...layers, [layerName]: {...layers[layerName], ...property}},
    });
  }

  @Action(SetLayerOrder)
  SetLayersOrder({patchState}: StateContext<LayerListModel>, {layersOrder}: SetLayerOrder) {
    patchState({
      layersOrder,
    });
  }

  @Action(AddActiveGroupIndex)
  AddActiveGroupIndex({getState, patchState}: StateContext<LayerListModel>, {index}: AddActiveGroupIndex) {
    const activeGroupIndex = [...getState().activeGroupIndex, index];
    patchState({activeGroupIndex});
  }

  @Action(ClearActiveGroupIndex)
  ClearActiveGroupIndex({patchState}: StateContext<LayerListModel>) {
    const activeGroupIndex: number[] = [];
    patchState({activeGroupIndex});
  }
}
